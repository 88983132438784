import React from "react";
import {classNames} from "../tools/react.dom.helpers";
import Icon from "./icon";
import T from "./t";
import Hint from "../component/hint";

const Text = ({className, caption, icon, iconColor, iconClassName, children, color = Text.COLOR.PRIMARY, weight, size = Text.SIZE.DEFAULT, alignment, ellipsis, lineClamp, html, tooltip}) => (
    <div className={classNames('p', className,
        size && 'p--'+size,
        color && 'p--' + color,
        weight && weight,
        alignment && alignment,
        ellipsis && 'ellipsis',
        lineClamp && 'line-clamp'
    )}
    style={lineClamp && {WebkitLineClamp: lineClamp}}>
        { children || <>
            { icon && <Icon icon={icon} color={iconColor} className={iconClassName} /> }
            { caption && <span className="p__txt">{html ? <T html>{caption}</T> : <T>{caption}</T>}</span> }
            { tooltip && <Hint text={tooltip}/> }
        </> }
    </div>
)

Text.COLOR = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    HINT: 'hint',
    ACCENT: 'accent',
    DANGER: 'danger',
    INHERIT: 'inherit',
}
Text.WEIGHT = {
    NORMAL: 'normal',
    MEDIUM: 'medium',
    BOLD: 'bold',
    LIGHT: 'light',
}
Text.SIZE = {
    DEFAULT: 'default_x',
    SMALL: 'small',
    DEFAULT_XL: 'default_xl',
}

Text.ALIGNMENT = {
    CENTER: 'text-center',
    LEFT: 'text-left',
    RIGHT: 'text-right'
}

export default Text;